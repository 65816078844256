<template>
  <PlaceInfo class="mt-10 lg:mx-56 sm:mx-10 mx-4"/>
</template>

<script>
import PlaceInfo from "@/components/places/editPlace/PlaceInfo";

export default {
  name: 'AddPlace',
  components: {PlaceInfo },

  mounted() {
    this.$store.commit('resetDynamicTableState')
  }
}
</script>